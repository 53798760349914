<template>
  <div class="editHoltel">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>编辑酒店</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane name="1" label="基本内容">
        <div class="content">
          <el-form
            ref="form"
            :model="formList"
            label-width="80px"
            :rules="step1Rules"
          >
            <el-form-item label="酒店名称">
              <el-input v-model.trim="formList.hotelName"></el-input>
            </el-form-item>
            <el-form-item label="酒店分类" class="hotelcate">
              <el-select placeholder="请选择" v-model.trim="CateValue1">
                <el-option
                  v-for="item in hotelCateList1"
                  :key="item.cateId"
                  :label="item.cateName"
                  :value="item.cateId"
                >
                </el-option>
              </el-select>
              <el-select placeholder="请选择" v-model.trim="CateValue2">
                <el-option
                  v-for="item in hotelCateList2"
                  :key="item.cateId"
                  :label="item.cateName"
                  :value="item.cateId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="酒店标签" class="start">
              <el-input v-model.trim="formList.hotelLabel"></el-input>
            </el-form-item>
            <el-form-item label="星级" class="fenshu">
              <el-select
                v-model="startvalue"
                placeholder="请选择"
                @change="onStartChnange"
              >
                <el-option
                  v-for="item in startType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="推荐理由">
              <el-input maxlength="26" v-model="formList.recommend"></el-input>
            </el-form-item>
            <el-form-item label="联系人" class="col">
              <el-input
                v-model.trim="formList.hotelLinkman"
                class="tel"
              ></el-input>
              <el-form-item
                label="客服电话"
                class="col"
                style="display: inline-block"
              >
                <el-input v-model="formList.hotelTel" class="tel"></el-input>
              </el-form-item>
            </el-form-item>

            <el-form-item label="所在街道" class="street">
              <el-select placeholder="请选择省" v-model="provinceValue">
                <el-option
                  v-for="item in provinceList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
              <el-select placeholder="请选择市" v-model.trim="cityValue">
                <el-option
                  v-for="item in cityList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
              <el-select placeholder="请选择地区" v-model.trim="areaValue">
                <el-option
                  v-for="item in areaList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
              <el-select placeholder="请选择街道" v-model="streetValue" @change="formatDEfaultAddress">
                <el-option
                  v-for="item in streetList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="详细地址" class="map">
               <my-map
              @selectAddress="onAddressChange"
              @onMapClick="onMapClick"
              :Address1="formList.hotelAddress"
              :pos="[formList.hotelLon,formList.hotelLat,]"
            ></my-map>
              <!-- <my-map
                @onAddressChange="onAddressChangeHandle"
                :Address1="formList.hotelAddress"
                :lon="obj.hotelLon"
                :lat="obj.hotelLat"
              > -->
              <!-- </my-map> -->
            </el-form-item>
            <el-form-item label="纬度" class="adress">
              <el-input readonly v-model="formList.hotelLat"></el-input>
              <span>经度</span>
              <el-input readonly v-model="formList.hotelLon"></el-input
            ></el-form-item>
            <el-form-item label="简介">
              <el-input
                rows="6"
                type="textarea"
                v-model="formList.hotelSummary"
                placeholder="请输入酒店简介"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="btn">
            <el-button type="primary" @click="next('2')">下一步</el-button>
            <el-button type="primary" @click="OnSave">保存</el-button>
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="2" label="酒店图片">
        <div class="content pic">
          <el-form ref="form" label-width="80px">
            <el-form-item label="展示图">
              <!-- v-if="formList.hotelShowImg !== ''" -->
              <send-image
                
                type="one"
                :images="formList.hotelShowImg"
                @addImageStr="(e) => addImageStr(e, 1)"
              ></send-image>
              <span style="color: red;"
                >每张图片不能超过500K，建议尺寸：335*172px</span
              >
            </el-form-item>
            <el-form-item label="分享图">
                            <!-- v-if="formList.shareImg !== ''" -->
              <send-image
                type="one"
                :images="formList.shareImg"
                @addImageStr="(e) => addImageStr(e, 2)"
              ></send-image>

              <span style="color: red;"
                >每张图片不能超过500K，建议尺寸：250*150px</span
              >
            </el-form-item>
            <el-form-item label="轮播图">
              <send-image
                v-if="formList.hotelImg.length > 0"
                type="more"
                :images="formList.hotelImg.join(',')"
                @addImageStrs="addImageStrs"
              ></send-image>
              <span style="color: red;"
                >每张图片不能超过500K，建议尺寸：345*187px</span
              >
            </el-form-item>
             <el-form-item label="PC轮播图" >
             <send-image type="morePc"
                 
                :images="formList.pcImg.join(',')" 
                 :maxLength="4"
                  @addImageStrsPc="addImageStrsPc"></send-image>
              <span style="color: red; margin-left: 20px"
                >每张图片不能超过500K，建议尺寸：1960*680px</span
              >
            </el-form-item>
          </el-form>
          <div class="btn">
            <el-button type="primary" @click="pre('1')">上一步</el-button>
            <el-button type="primary" @click="next('3')">下一步</el-button>
            <el-button type="primary" @click="OnSave">保存</el-button>
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="3" label="规则详情">
        <div class="content rules">
          <el-form ref="form" label-width="80px">
            <el-form-item label="预定规则">
              <el-input
                type="textarea"
                :rows="10"
                placeholder="请输入预定规则"
                v-model.trim="formList.hotelBookNotice"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="退订规则">
              <div>
                离入住时间
                <input
                  type="number"
                  v-model.trim="formList.hotelOverTime3"
                />小时免退订费用
              </div>
              <div>
                离入住时间
                <input
                  type="number"
                  v-model.trim="formList.hotelOverTime1"
                />小时以上,手续费
                <input type="number" v-model.trim="formList.hotelCharge1" />%
              </div>
              <div>
                离入住时间
                <input
                  type="number"
                  v-model.trim="formList.hotelOverTime2"
                />小时以内,手续费
                <input type="number" v-model.trim="formList.hotelCharge2" />%
              </div>
            </el-form-item>
            <el-form-item label="酒店详情">
              <!-- <quill-edito
                class="richEdit"
                :options="quillOption"
                v-model.trim="formList.hotelDetails"
              >
              </quill-edito> -->
               <VueUeditorWrap v-model="formList.hotelDetails" :config="myConfig" ref="myTextEditor"></VueUeditorWrap>
            </el-form-item>
            <el-form-item>
              <span style="color: red">建议尺寸750*1500px</span>
            </el-form-item>
          </el-form>
          <div class="btn">
            <el-button type="primary" @click="pre('2')">上一步</el-button>
            <el-button type="primary" @click="OnSave">保存</el-button>
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import VueUeditorWrap from "vue-ueditor-wrap";
import { hotelCateList, subClassification } from "../../api/hotel";
import { getHotelDetails, editHotel } from "../../api/hotelList";
import myMap from "../../components/map";
import { sendFile } from "../../api/sendFile";
import quillConfig from "../../../quill-config";
import { Quill } from "vue-quill-editor";

import SendImage from "../../components/sendImage.vue";
import {
  getAllProvince,
  getCityByProvince,
  getAreaByCity,
  getStreetByArea,
} from "../../api/address";
export default {
  name: "editHotel",
  components: {
    myMap: myMap,
    SendImage,
     VueUeditorWrap,
  },
  props: {
    id: {
      type: [Number, String],
    },
  },
  data() {
    const self = this;
    return {
      myConfig: {
        toolbars: [
          [
            'simpleupload', //单图上传
            "insertvideo",
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 400, // 初始容器高度
        initialFrameWidth: "110%", // 初始容器宽度
        //  serverUrl: "/api/config",
        serverUrl: "/config",//打包
        //  UEDITOR_HOME_URL: "/UEditor/",
        UEDITOR_HOME_URL: "/static/UEditor/",//打包
        maximumWords: "100000000",
      },
      quillOption: quillConfig,
      obj: null,
      address: "",
      hotelDetailsList: {},
      activeName: "1",
      hotelCateList1: [],
      hotelCateList2: [],
      CateValue1: "",
      CateValue2: "",
      provinceValue: "",
      cityValue: "",
      areaValue: "",
      streetValue: "",
      provinceList: [],
      cityList: [],
      cityList: [],
      areaList: [],
      streetList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      startType: [
        {
          value: "5",
          label: "五星级",
        },
        {
          value: "4",
          label: "四星级",
        },
        {
          value: "3",
          label: "三星级",
        },
      ],
      startvalue: "",
      formList: {
        hotelId: 0,
        hotelName: "",
        // hotelCateId: "",
        hotelLabel: "",
        hotelStartType: "",
        hotelScore: 4.9,
        hotelLinkman: "",
        streetCode: "",
        hotelAddress: "",
        hotelSummary: "",
        hotelShowImg: "",
        hotelLat: null,
        hotelLon: null,
        hotelTel: null,
        hotelBookNotice: "",
        hotelOverTime3: null,
        hotelOverTime1: null,
        hotelCharge1: null,
        hotelImg: [],
        hotelOverTime2: null,
        hotelCharge2: null,
        hotelDetails: "",
        recommend: "",
        shareImg: "",
        pcImg:"",
      },
      step1Rules: {},
    };
  },
  mounted() {
    quillConfig.register(Quill);
    quillConfig.initButton();
  },
  computed: {},
  watch: {
    CateValue1: async function () {
      const { data } = await subClassification({
        fatherId: this.CateValue1,
      });
      // data.list.map((item) => {
      //   item.label = item.cateName;
      //   item.value = item.id;
      // });
      this.hotelCateList2 = data.data;
    },
    provinceValue: async function () {
      const { data } = await getCityByProvince({
        provinceCode: this.provinceValue,
      });
      this.cityList = data.data;
      this.formatDEfaultAddress()
    },
    cityValue: async function () {
      const { data } = await getAreaByCity({
        cityCode: this.cityValue,
      });
      this.areaList = data.data;
      this.formatDEfaultAddress()
    },
    areaValue: async function () {
      const { data } = await getStreetByArea({
        areaCode: this.areaValue,
      });
      this.streetList = data.data;
      this.formatDEfaultAddress()
    },
  },
  created() {
    this.getHotelCateList();
    this.getAllProvinceList();
    this.getHotelInfo();
  },
  methods: {
     onMapClick({address,lat,lon}){
      this.formList.address = address
      this.formList.hotelLat = lat
      this.formList.hotelLon = lon

    },
    formatDEfaultAddress(){
      // 获取选择默认地址
       const pro = this.provinceList.filter(item=>{
       return item.code == this.provinceValue
      })
       const city= this.cityList.filter(item2=>{
       return item2.code == this.cityValue
      })
      const area = this.areaList.filter(item3=>{
       return item3.code == this.areaValue
      })
      const street = this.streetList.filter(item4=>{
       return item4.code == this.formList.streetCode
      })
      let addressArr = [...pro,...city,...area,...street]
      let defaultArr = []
     addressArr.forEach(item5=>{
      defaultArr.push(item5.name)
     })
     this.formList.address =  defaultArr.join('')
     console.log( defaultArr.join(''),'defaultAddress')
    },
    onAddressChange({pname,cityname,adname,address,location}) {
        const { lat,lng} = location
      console.log(address, lat,lng);
      this.formList.hotelAddress = pname+cityname+adname+address;
      this.formList.hotelLon = lng;
      this.formList.hotelLat = lat;
    },

    // onAddressChangeHandle(value, lon, lat) {
    //   this.formList.hotelAddress = value;
    //   this.formList.hotelLon = lon;
    //   this.formList.hotelLat = lat;
    // },

    onSearchAddress() {
      this.address = this.formList.hotelAddress;
    },
    async getHotelInfo() {
      const { data } = await getHotelDetails({
        hotelId: Number(this.id),
      });
      var obj = data.data[0];
      this.obj = obj;
      this.formList.hotelName = data.data[0].hotelName;
      this.formList.hotelLabel = data.data[0].hotelLabel[0];
      this.formList.hotelAddress = data.data[0].hotelAddress;
      this.formList.hotelLat = data.data[0].hotelLat;
      this.formList.hotelLon = data.data[0].hotelLon;
      this.CateValue1 = data.data[0].hotelFatherCateId;
      this.CateValue2 = data.data[0].hotelCateId;
      this.formList.hotelLinkman = data.data[0].hotelLinkman;
      this.formList.hotelTel = data.data[0].hotelTel;
      this.formList.hotelScore = data.data[0].hotelScore;
      this.formList.hotelStartType = data.data[0].hotelStartType;
      this.formList.hotelSummary = data.data[0].hotelSummary;
      this.formList.hotelBookNotice = data.data[0].hotelBookNotice;
      this.formList.hotelOverTime3 = data.data[0].hotelOverTime3;
      this.formList.hotelOverTime1 = data.data[0].hotelOverTime1;
      this.formList.hotelCharge1 = data.data[0].hotelCharge1;
      this.formList.hotelOverTime2 = data.data[0].hotelOverTime2;
      this.formList.hotelCharge2 = data.data[0].hotelCharge2;
      this.formList.hotelShowImg = data.data[0].hotelShowImg;
      this.dialogImageUrl = data.data[0].hotelShowImg;
      this.formList.hotelImg = data.data[0].hotelImg;
      this.formList.hotelDetails = data.data[0].hotelDetails;
      this.provinceValue = data.data[0].hotelProvinceCode;
      this.cityValue = data.data[0].hotelCityCode;
      this.areaValue = data.data[0].hotelAreaCode;
      this.formList.streetCode = data.data[0].hotelStreetCode;
      this.streetValue = data.data[0].hotelStreetCode;
      this.startvalue = data.data[0].hotelStartType + "";
      this.formList.recommend = data.data[0].recommend;
      this.formList.shareImg = data.data[0].shareImg;
      this.formList.pcImg = data.data[0].pcImg || [];
      this.hotelDetailsList = data.data[0];
     
    },
    async getHotelCateList() {
      const { data } = await subClassification({
        fatherId: 0,
      });

      this.hotelCateList1 = data.data;
    },
    async getAllProvinceList() {
      const { data } = await getAllProvince();
      this.provinceList = data.data;
    },
    addImageStr(image, type) {
      if (type === 1) {
        this.formList.hotelShowImg = image;
      } else if (type === 2) {
        this.formList.shareImg = image;
      }
    },
    addImageStrs(image) {
      this.formList.hotelImg = image;
    },
   addImageStrsPc(image) {
      this.formList.pcImg = image;
    },
    onTitleClick(active) {
      this.active = active;
    },

    onStartChnange() {
      this.formList.hotelStartType = Number(this.startvalue);
    },
    onStreetChange() {
      this.startvalue = Number(this.formList.streetCode);
    },
    async OnSave() {
      console.log( this.formList.pcImg,)
      console.log( this.formList,)
          for (const key in this.formList) {
        if (this.formList[key] === null || this.formList[key] === "") {
          return this.$message({
            message: "请把数据填写完整",
            type: "error",
          });
        }
      }
      const { data } = await editHotel({
        hotelId: Number(this.id),
        hotelName: this.formList.hotelName,
        hotelCateId: Number(this.CateValue2),
        hotelLabel: this.formList.hotelLabel,
        hotelStartType: Number(this.formList.hotelStartType),
        hotelScore: Number(this.formList.hotelScore),
        hotelLinkman: this.formList.hotelLinkman,
        streetCode: Number(this.streetValue),
        hotelAddress: this.formList.hotelAddress,
        hotelSummary: this.formList.hotelSummary,
        hotelShowImg: this.formList.hotelShowImg,
        hotelLat: this.formList.hotelLat,
        hotelLon: this.formList.hotelLon,
        hotelTel: this.formList.hotelTel,
        hotelBookNotice: this.formList.hotelBookNotice,
        hotelOverTime3: Number(this.formList.hotelOverTime3),
        hotelOverTime1: Number(this.formList.hotelOverTime1),
        hotelCharge1: Number(this.formList.hotelCharge1),
        hotelImg: this.formList.hotelImg.join(","),
        hotelOverTime2: Number(this.formList.hotelOverTime2),
        hotelCharge2: Number(this.formList.hotelCharge2),
        hotelDetails: this.formList.hotelDetails,
        recommend: this.formList.recommend,
         pcImg: this.formList.pcImg.join(","),
          shareImg: this.formList.shareImg,
      });
      if (data.code == 0) {
        this.$message({
          message: data.msg,
          type: "success",
        });
        this.$router.go(-1);
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
      }
    },
    onSendImg() {
      this.$refs.fileRefs.click();
    },
    async onFileChange(event) {
      var files = event.target.files;
      if (files && files.length > 0) {
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);
        this.formList.hotelShowImg = data.data[0].src;
      }
    },
    onSendImg1() {
      this.$refs.fileRefs1.click();
    },
    async onFileChange1(event) {
      var files = event.target.files;
      if (files && files.length > 0) {
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);
        if (this.formList.hotelImg.length < 10) {
          this.formList.hotelImg.push(data.data[0].src);
        } else {
          this.$message({
            showClose: true,
            message: "最多只能添加10张图片哦",
            type: "warning",
          });
          return;
        }
      }
    },
    onClose() {
      this.formList.hotelShowImg = "";
    },
    onClose1(item) {
      this.formList.hotelImg = this.formList.hotelImg.filter((value) => {
        return value != item;
      });
    },
    next(active) {
      this.activeName = active;
    },
    pre(active) {
      this.activeName = active;
    },
  },
};
</script>
<style lang="less" scoped>
.editHoltel {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .el-steps {
    margin-top: 50px;
  }
  .content {
    // width: 900px;
    // margin: 50px auto;
    .adress {
      span {
        margin: 20px;
      }
      .el-input {
        width: 363px;
      }
    }
    .col {
      width: 400px;

      span {
        margin: 20px;
      }
      .tel {
        width: 354px !important;
      }
      .el-input {
        width: 210px;
      }
    }
    /deep/ .el-form {
      .el-form-item {
        .el-form-item__content {
          width: 800px;
        }
      }
      .hotelcate {
        .el-form-item__content {
          .el-select {
            width: 400px !important;
          }
        }
      }
      .map {
        .el-form-item__content {
          // height: 320px;
          .container {
            width: 800px;
            // height: 270px;
            .amap-container {
              // height: 270px;
            }
          }
        }
      }
      .street {
        .el-select {
          width: 200px;
        }
      }
      .fenshu {
        margin-left: 42px;
      }
      .start,
      .fenshu {
        width: 400px;
        display: inline-block;
        .el-form-item__content {
          .el-select {
            width: 356px;
          }
          width: 356px;
        }
      }
      .el-form-item__content {
        // display: flex;
        // justify-content: center;
        width: 500px;
        .el-vue-amap-container {
          // height: 300px;
        }
        .richEdit {
          height: 500px;
          width: 800px;
          .ql-container {
            // height: 400px;
          }
        }
      }
    }
  }
  .pic {
    /deep/.el-form {
      .el-form-item__content {
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        // width: 500px;
        // height: 200px;
        .img {
          position: relative;
          height: 100px;
          width: 100px;
          margin: 0 20px;
          border: 1.5px solid #c0ccda;

          img {
            height: 100%;
            width: 100%;
            // border: 1px solid #c0ccda;
          }
          div {
            position: absolute;
            top: 0;
            right: 5px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }
        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          box-sizing: border-box;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }
      }
    }
  }
  .rules {
    /deep/.el-form {
      .el-form-item__content {
        div {
          color: #606266;
          font-size: 16px;
          input {
            width: 135px;
          }
        }
      }
    }
  }
  .btn {
    width: 500px;
    margin: 0 auto;
  }
}
</style>